h1,
h2,
h3,
h4,
h5 {
  text-align: center;
}

.multiplechoicebutton {
    box-shadow: 2px 2px 1px rgba(50, 50, 50, 0.7);
    width:400px;
    text-transform: none !important;
}

.multiplechoicebutton.wrong {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}

  
@keyframes shake {
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
}