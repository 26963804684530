  .lessongrid {
    padding: var(--gutter);
    align-content: start;
    overflow-y: scroll;
  }
  
  .hs:before,
  .hs:after {
    content: '';
  }

  .hs::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
  
  .hs {
    display: flex;
    padding: 0;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    scrollbar-width: none; 
    margin-bottom: 50px;
  }

  .hs a {
    display: block; 
  }
  
  .sectiontitle {
    margin-top: 40px;
    margin-bottom: 10px;
    text-align: start;
  }

  .lessongrid h1, h2, h3, h4, h5, h6 {
    text-align: start;
  }

  h1 {
    color:#3eb489;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .sectiontitle {
    color: #d2afed;
  }

  .lessonlink {
    color: #551a8b;
    text-decoration: none;
  }

  .lessonrow {
    display: flex;
    flex-wrap: wrap;
  }
  
  