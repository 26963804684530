.selectimagebutton {
    box-shadow: 3px 3px 1.5px rgba(50, 50, 50, 0.7);
    width:500px;
    height:200px;
    text-transform: none !important;
}

.selectimage {
    width: 266px;
    height: 160px;
}

.selectimagebutton.wrong {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;
  
    /* When the animation is finished, start again */
    animation-iteration-count: 1;
}

.wordtext {
    text-align: center;
    color: black;
}

@keyframes shake {
0% { transform: translate(1px, 1px) rotate(0deg); }
10% { transform: translate(-1px, -2px) rotate(-1deg); }
20% { transform: translate(-3px, 0px) rotate(1deg); }
30% { transform: translate(3px, 2px) rotate(0deg); }
40% { transform: translate(1px, -1px) rotate(1deg); }
50% { transform: translate(-1px, 2px) rotate(-1deg); }
60% { transform: translate(-3px, 1px) rotate(0deg); }
70% { transform: translate(3px, 1px) rotate(-1deg); }
80% { transform: translate(-1px, -1px) rotate(1deg); }
90% { transform: translate(1px, 2px) rotate(0deg); }
100% { transform: translate(1px, -2px) rotate(-1deg); }
}
