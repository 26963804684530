.dialoguecontainer {
    width: 600px;
}

.speechbox {
    height: '50%';
}

.speechbutton {
    box-shadow: 3px 3px 1.5px rgba(50, 50, 50, 0.7);
}

.audiocontrol {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

