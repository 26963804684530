
.floatingbutton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 100px;
    height: 100px;
    background-image: url("../assets/icons/translateicon.png");
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid white;
    text-transform: none !important;
}

.floatingbutton:hover::before {
    content: "Çeviriler";
    position: absolute;
    top: -40px;
    left: 5px;
    width: 70px;
    padding: 5px;
    background-color: white;
    color: black;
    border-radius: 5px;
    text-align: center;
    font-size: 12px;
  }