.fillintable {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 350px;
  }
  .fillintable tr th,
  .fillintable tr td {
    border-right: 1.5px solid black;
    border-bottom: 1.5px solid black;
    padding: 20px;
  }
  .fillintable tr td:first-child {
    border-left: 1.5px solid black;
  }

  .fillintable tr td {
    font-weight: 600;
  }
  
  /* top-left border-radius */
  .fillintable tr:first-child td:first-child {
    border-top-left-radius: 6px;
    border-top: 1.5px solid black;
  }
  
  /* top-right border-radius */
  .fillintable tr:first-child td:last-child {
    border-top-right-radius: 6px;
    border-top: 1.5px solid black;
  }
  
  /* bottom-left border-radius */
  .fillintable tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }
  
  /* bottom-right border-radius */
  .fillintable tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }

  .blink_me {
    animation: blinker 1.8s linear infinite;
    font-weight: bold;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }