.dialoguebox {
    width: 300px;
    height: 100px;
    border-radius: 5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    padding: 20px;
}

.left {
    align-self: flex-start;
}

.right {
    align-self: flex-end;
}

.dialogueimage {
    width: 50px;
    height: 50px;
}

.dialogueboxwrapper {
    cursor: pointer;
}

.textbox {
    padding: 20px;
    border-radius: 1em;
    font-weight: 600;
    text-align: start;
    line-height: 2;
}



