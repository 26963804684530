.player {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.audiocontrol {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
