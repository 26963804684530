.statsbox {
    height: 120px;
    width: 120px;
}

@media (max-width: 600px) {
    .statsbox {
      width: 100%;
      min-width: 80px;
    }
}