.lessoncard {
  position:relative;
  margin:5px;
  scroll-snap-align: center;
  padding: calc(var(--gutter) / 2 * 1.5);
  display: flex;
  border-radius: 8px;
  font-weight: 700;
  width: 200px;
  height: 120px;
}

.lessoncard1 {
    background: #FF985B;
  }

.lessoncard2 {
  width:250px;
  height:150px;
  margin:7px;
  background: #9CCC65;
}

.lessoncard3 {
  background: #4CC3D9;
}

.lessoncard4 {
  background: #FD9598;
}

.lessontypesymbol {
  position: absolute;
  bottom: 10%;
  right: 10%;
} 



  

