.videostack {
    width: 60%;
    justify-content: center;
    align-items: center;
}

@media (max-width: 800px) {
    .videostack {
      width: 100%;
    }
}